/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // toggles hamburger and nav open and closed states

        if (!Cookies.get('alert') && $('#modal').length) {
          $('#modal').show();
        }

        $('.modal .btn-close').click(function() {
          $('#modal').hide();
          Cookies.set('alert', true, {
            expires: 1,
          });
        });


        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function(e) {
          if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);

        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });

        $(document).on('click touch', function(e) {

          if( $(e.target).parent('.menu-item-has-children').length && $(e.target).parents('.sideNav').length ) {
            e.preventDefault();
            e.stopPropagation();

            var menuOpen = false;
            var parent = $(e.target).parent('.menu-item-has-children');
            var subMenu = $(e.target).siblings('.sub-menu');

            if(subMenu.hasClass('open') || parent.hasClass('open')) {
              menuOpen = true;
            }

            $('.menu-item-has-children').each(function() {
              if ($(this).hasClass('open')) {
                $(this).removeClass('open');
              }
            });

            $('.sub-menu').each(function() {
              if ($(this).hasClass('open')) {
                $(this).removeClass('open');
              }
            });

            if (menuOpen === false) {
              subMenu.addClass('open');
              parent.addClass('open');
            } else {
              subMenu.removeClass('open');
              parent.removeClass('open');
            }

          } else if(!$(e.target).hasClass('sub-menu') && $('.sub-menu').has($(e.target)).length === 0) {

            $('.menu-item-has-children').each(function() {
              if ($(this).hasClass('open')) {
                $(this).removeClass('open');
              }
            });

            $('.sub-menu').each(function() {
              if ($(this).hasClass('open')) {
                $(this).removeClass('open');
              }
            });

          }

        });

        // Select all links with hashes
        $('a[href*="#"]')
          // Remove links that don't actually link to anything
          .not('.scrollup')
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function(event) {
            // On-page links
            if (
              location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
              location.hostname === this.hostname
            ) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 700, function() {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  }
                });
              }
            }
          });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        if ($('.testimonial-carousel').length) {
					$('.testimonial-carousel').slick({
						slidesToShow: 3,
						slidesToScroll: 1,
						appendArrows: '.slider-nav',
						appendDots: '.slider-nav',
						arrows: true,
						dots: true,
						responsive: [{
								breakpoint: 1199,
								settings: {
									slidesToShow: 2,
									slidesToScroll: 1
								}
							},
							{
								breakpoint: 767,
								settings: {
									slidesToShow: 1,
									slidesToScroll: 1
								}
							}
						]
					});
				}

      }
    },
    'single_products': {
      init: function() {

        $('#imageGallery').lightSlider({
          gallery:true,
          item:1,
          loop:true,
          thumbItem:4,
          slideMargin:0,
          enableDrag: false,
          currentPagerPosition:'left',
          onSliderLoad: function(el) {
              el.lightGallery({
                selector: '#imageGallery .lslide'
              });
            }
          });

      }
    },
    'has_map': {
      init: function() {
          document.initMap = function() {
            var map;
            function initialize_gmap(bound) {
            	var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            	var isDraggable = w > 480 ? true : false;
            	var settings = {
            		zoom: 14,
            		center: bound.getCenter(),
            		scrollwheel: false,
            		draggable: isDraggable,
            		mapTypeControl: false,
            		mapTypeControlOptions: {
            			style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
            		},
            		navigationControl: false,
            		navigationControlOptions: {
            			style: google.maps.NavigationControlStyle.SMALL
            		},
            		mapTypeId: google.maps.MapTypeId.ROADMAP
            	};
            	map = new google.maps.Map(document.getElementById("map_canvas"), settings);
            	var center;
            	function calculateCenter() {
            		center = map.getCenter();
            	}
            	google.maps.event.addDomListener(map, 'idle', function() {
            		calculateCenter();
            	});
            	google.maps.event.addDomListener(window, 'resize', function() {
            		map.setCenter(center);
            	});
            }

            function addMarker(point_lat, point_long, address, title, showMarker) {
            	var point = new google.maps.LatLng(point_lat, point_long);
                var formattedAddress = address.split(' ').join('+');
            	var contentString = '<div id="gmapContent" style="text-align:left;">' +
            		'<a href="https://www.google.com/maps/dir/?api=1&destination=' + formattedAddress + '" target="_blank"><h3>' + title + '</h3></a>' +
            		'<div id="bodyContent"><p>' + address + '</p>' +
                '<a href="https://www.google.com/maps/dir/?api=1&destination=' + formattedAddress + '" target="_blank">' +
                '<i class="fa fa-external-link"></i> <strong>Get Directions</strong></a></div></div>';
            	var companyMarker = new google.maps.Marker({
            		position: point,
            		map: map,
            		title: title
            	});
                var infowindow = new google.maps.InfoWindow();
            	if( showMarker === true ) {
            		infowindow.setContent(contentString);
            		infowindow.open(map, companyMarker);
            	} else {
            		google.maps.event.addListener(companyMarker, 'click', function() {
            			infowindow.setContent(contentString);
            			infowindow.open(map, this);
            		});
            	}

            }
            var locations = [];
            $('.location-map').each(function() {
                var location = [];
            	location.push(parseFloat($(this).find('.map-data').find('.lat').data('lat')));
            	location.push(parseFloat($(this).find('.map-data').find('.lng').data('lng')));
            	location.push($(this).find('.map-data').find('.address').data('address'));
            	location.push($(this).find('.map-data').find('.title').data('title'));
            	locations.push(location);
            });

            if ($('#map_canvas').length) {
            	var showMarker = true;
            	var offset = 0.004;
            	if( locations.length > 1 ) {
            		showMarker = false;
            		offset = 0;
            	}
            	var bound = new google.maps.LatLngBounds();
            	for (i = 0; i < locations.length; i++) {
            		bound.extend(new google.maps.LatLng(locations[i][0] + offset, locations[i][1]));
            	}
            	initialize_gmap(bound);
            	for (i = 0; i < locations.length; i++) {
            		addMarker(locations[i][0], locations[i][1], locations[i][2], locations[i][3], showMarker);
            	}
            }
        };
        var maps_api_key = 'AIzaSyDXYFw0w7jD3UL-Ri1y7fkTRFuYKk72Wsg';
        var maps_callback = 'document.initMap';
        var maps_api_url = 'https://maps.google.com/maps/api/js';
        var maps_script_tag = document.createElement('script');
        maps_script_tag.setAttribute('type', 'text/javascript');
        maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
        maps_script_tag.setAttribute('defer', '');
        maps_script_tag.setAttribute('async', '');
        document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
